#logger{
	position: fixed;
	width:100%;
	height:20%;
	bottom:0;
	margin:0;
	padding:20px;
	z-index: 200;
	font-family: sans-serif;
	background-color: #222;
	color: white;
	overflow-y: scroll;
	overflow-wrap: break-word;
	transition: all .5s;
	&.hide{
		height:25px;
	}
	.log-boolean,
.log-undefined {color: magenta;}
.log-object{color: orange;}
.log-string {color: white;}
.log-number {color: cyan;}
	hr{
		padding: 0;
		margin:2px;
		border-bottom:1px solid #555;
	}
}

.close {
  position: fixed;
  right: 10px;
  bottom: calc(20% - 25px);
  width: 20px;
  height: 20px;
  opacity: 0.3;
  transition: all .5s;
  &.hide{
  	bottom:15px;
  	right:10px;
  }
}
.close:hover {
  opacity: 1;
}
.close:before, .close:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 23px;
  width: 2px;
  background-color: #fff;
  transition: all .3s;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}
.hide:after,.hide:before{
  	transform: rotate(90deg) !important;
  }


.loader{
	z-index: 200;
	position: fixed;
	width: 100vw;
	height:100vh;
	padding:0;
	margin:0;
	background: #fff;
	top:0;
	left:0;
	.spinner{
		position:absolute;
		width:100%;
		color: black;
		top:calc(50vh - 1rem);
	}
	.message{
		position: absolute;
		color: red;
    	top: 3rem;
	}
}

#miniplayer{
	background-color: green;
	width:200px;
	height:150px;
	position: fixed;
	bottom:20px;
	right:20px;
	z-index: 20000;
}